<template>
  <div class="card card-body shadow-sm">
    <app-basic-table
      ref="basic_table"
      :table-name="$t('users.user list')"
      :filters.sync="filters"
      :setting-columns="columns"
      :endpoint-list="ENDPOINT.BEACON_LIST"
      @resetAllSearch="onResetAllSearch"
      @searched="onSearch"
    >
      <template v-slot:filters>
        <div class="row mt-3">
          <div class="col-xl-4 col-lg-6 col-sm-6">
            <div class="form-group row">
              <div class="col-xl-12 col-lg-12 col-sm-12  mt-0">
                <label class="align-self-center mb-0 mr-5">{{ filters.status_equal.label }}</label>
              </div>
              <div class="col-xl-12 col-lg-12 col-sm-12  mt-3">
                <app-radio v-for="(item, index) in BEACONS_CONSTANT.FILTER_STATUS"
                           :isMarginLeft="index !=0 ? true : false" :key="item.id" :label="item.name"
                           :val="item.id" v-model="filters.status_equal.value"/>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-6 col-sm-6">
            <div class="form-group row">
              <div class="col-xl-12 col-lg-3 col-sm-3  mt-0">
                <label>{{ filters.apartment_id_equal.label }}</label>
              </div>
              <div class="col-xl-12 col-lg-9 col-sm-9  mt-0">
                <app-select :name="filters.apartment_id_equal.name"
                            input-style="normal"
                            v-model="filters.apartment_id_equal.value"
                            :options-data="meta.apartments"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-sm-4">
            <div class="form-group">
              <label>{{ filters.name_equal.label }}</label>
              <app-input :name="filters.name_equal.name" input-style="normal"
                         v-model="filters.name_equal.value"
              />
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-sm-4">
            <div class="form-group">
              <label>{{ filters.beacon_id_like.label }}</label>
              <app-input :name="filters.beacon_id_like.name" input-style="normal"
                         v-model="filters.beacon_id_like.value"
              />
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-sm-4">
            <div class="form-group">
              <label>{{ filters.serial_equal.label }}</label>
              <app-input :name="filters.serial_equal.name" input-style="normal"
                         v-model="filters.serial_equal.value"
              />
            </div>
          </div>

        </div>

      </template>


      <template v-slot:body-cell-apartment_id="props">
        <td class="app-align-middle">
          <p :data-original-title="getApartmentName(props.row.apartment_id)" style="color: #007bff;"
             @click="handlerApartmentNameClick(props.row)" class="apartment_name app-table-p app-cell-tooltip mb-0">
            {{props.row.apartment_name}}
          </p>
        </td>
      </template>

      <template v-slot:body-cell-status_enabled="props">
        <td class="app-align-middle">
          <p class="app-table-p app-cell-tooltip mb-0" >
            {{getStatusEnabled(props.row.status_enabled)}}
          </p>
        </td>
      </template>

      <template v-slot:body-cell-updated_at="props">
        <td class="app-align-middle">
          <p class="app-table-p app-cell-tooltip mb-0" >
            {{props.row.updated}}
          </p>
        </td>
      </template>

      <template v-slot:table-menu-right>
        <button @click="handleBtnCreateClick()" class="btn btn-warning">
          {{ $t("common.create") }}
        </button>
      </template>

      <template v-slot:body-cell-edit="props">
        <td class="app-align-middle text-center app-p-none">
          <button
            @click="handleBtnUpdateClick(props.row)"
            class="btn btn-primary"
          >
            {{ $t("common.edit") }}
          </button>
        </td>
      </template>

      <template v-slot:body-cell-history="props">
        <td class="app-align-middle text-center app-p-none">
          <button
            @click="handleBtnHistoryClick(props.row)"
            class="btn btn-primary"
          >
            {{ $t("beacons.history") }}
          </button>
        </td>
      </template>

    </app-basic-table>

    <!--    <create-component-->
    <!--      ref="modal_create"-->
    <!--      @onSuccess="handleCreateSuccess"-->
    <!--    ></create-component>-->

    <!--    <update-component-->
    <!--      @onSuccess="handleUpdateSuccess"-->
    <!--      ref="modal_update"-->
    <!--    ></update-component>-->

  </div>
</template>

<script>
  import AppBasicTable from "@components/_common/list/AppBasicTable";
  import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
  import {ENDPOINT} from "../../../constants/api";
  import {FACILITIES_CONSTANT} from "../../../constants/facilities";
  import {BEACONS_CONSTANT} from "../../../constants/beacons";

  export default {
    name: "FacilityList",
    components: {
      "app-basic-table": AppBasicTable,
      AppDateRangePicker,
    },
    data() {
      return {
        idCache: null,
        paramGetList: {},
        filters: {
          apartment_id_equal: {
            name: "apartment_id",
            condition: "equal",
            label: this.$t("common.mansion")
          },
          serial_equal: {
            name: "serial_like",
            condition: "like",
            label: this.$t("beacons.serial")
          },
          name_equal: {
            name: "name_like",
            condition: "like",
            label: this.$t("beacons.name")
          },
          status_equal: {
            name: "status_enabled",
            condition: "equal",
            label: this.$t("beacons.status")
          },
          beacon_id_like: {
            name: "beacon_id_like",
            condition: "like",
            label: this.$t("beacons.beacon_id")
          },
        },
        columns: [
          {name: "id", label: this.$t("common.id"), sortable: true, textAlign: 'text-center'},
          {name: "name", label: this.$t("beacons.name"), sortable: true},
          {name: "beacon_id", label: this.$t("beacons.beacon_id")},
          {name: "serial", label: this.$t("beacons.serial")},
          {name: "status_enabled", label: this.$t("beacons.status"), sortable: true, textAlign: 'text-center'},
          {
            name: "number_of_peoples",
            label: this.$t("beacons.number_of_peoples"),
            sortable: true,
            textAlign: 'text-center'
          },
          {name: "apartment_id", label: this.$t("beacons.mansion"), sortable: true, textAlign: 'text-center'},
          {name: "created_at", label: this.$t("beacons.created_at"), sortable: true, textAlign: 'text-center'},
          {name: "updated_at", label: this.$t("beacons.updated_at"), sortable: true, textAlign: 'text-center'},
          {name: "history", label: this.$t("beacons.history"), textAlign: 'text-center'},
          {name: "edit", label: this.$t("common.edit"), textAlign: 'text-center'},

        ],
        meta: {
          apartments: [],
        },
        endPoint: ''
      };
    },
    watch: {},
    methods: {
      handleBtnCreateClick() {
        this.$router.push({name: this.ROUTES.ADMIN.BEACONS_CREATE})
      },

      handleBtnUpdateClick(entry) {
        this.$router.push({name: this.ROUTES.ADMIN.BEACONS_EDIT, params: {id: entry.id}})
      },
      handleBtnHistoryClick(entry) {
        this.$router.push({name: this.ROUTES.ADMIN.BEACONS_HISTORY, params: {id: entry.beacon_id} , query : {
          'filters.date.equal' : moment().format('YYYY-MM-DD')
        }})
      },
      handleBtnManageStatus(entry) {
        this.$router.push({name: this.ROUTES.ADMIN.FACILITY_MANAGE_STATUS, params: {id: entry.id}})
      },
      handleBtnPlanList(entry) {
        this.$router.push({name: this.ROUTES.ADMIN.FACILITY_PLAN_LIST, params: {facility_id: entry.id}})
      },
      handlerApartmentNameClick(entry) {
        let routeApartment = this.$router.resolve({name: this.ROUTES.ADMIN.APARTMENT_EDIT, params: {id: entry.apartment_id}});
        window.open(routeApartment.href, '_blank');
      },

      getStatusEnabled(status) {
        let data = BEACONS_CONSTANT.STATUS.find(({id}) => id === status);
        return data.name
      },
      getColorStatusDispCrowded(status) {
        let data = FACILITIES_CONSTANT.disp_crowed.find(({id}) => id === status);
        return data.color
      },
      onResetAllSearch() {
        this.$router.push({
          name: this.$route.name,
          query: {
            'filters.status_enabled.equal': 'all',
            'filters.apartment_id.equal' : 'all'
          }
        })
      },
      onSearch() {
        // this.getTourSelectName();
      },
      getApartmentName(apartmentId) {
        let apartment = this.meta.apartments.find(({id}) => id === apartmentId);
        if (apartment == undefined) {
          return '';
        }
        return apartment.name;
      },
    },
    mounted: function () {
      // this.getParamsFilters({})
      this.$request.get(this.ENDPOINT.UI_HELPER_CUSTOMER_MEALS).then(res => {
        this.meta = {
          apartments: res.data.apartments,
        }
        this.meta.apartments.unshift({
          id : "all",
          name : ""
        })
      })
    },
  }
</script>

<style scoped>

</style>
